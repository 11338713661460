<template>
  <div class="d-flex justify-content-center align-items-center h-auto">
    <div class="row g-6 g-xl-9 justify-content-center" style="margin-top: 10%">
      <!--begin::Card-->
      <div
        class="
          card
          mx-2
          d-flex
          align-items-center
          justify-content-center
          shadow-sm
          hover-effect
        "
        style="width: 400px; min-height: 300px; height: 40vh"
      >
        <router-link
          :to="'/interviewer/invite-multi-candidate/' + jobPostingId"
        >
          <div
            class="
              card-body
              d-flex
              justify-content-center
              align-items-center
              text-center
              flex-column
              p-8
            "
          >
            <a
              class="
                text-gray-800 text-hover-primary
                d-flex
                flex-column
                align-items-center
              "
            >
              <div class="mb-5">
                <img
                  alt="Logo"
                  class="h-70px w-70px"
                  src="/media/avatars/userAccount.png"
                  style="width: 40px"
                />
              </div>
              <div class="fs-2 fw-bolder mb-2">
                {{ $t("modals.interview.candidate.addMultiCandidates") }}
              </div>
            </a>
            <div class="fs-7 fw-bold text-gray-400 text-center">
              {{ $t("modals.interview.candidate.addMultiCandidatesDesc") }}
            </div>
          </div>
        </router-link>
      </div>
      <!--end::Card-->

      <!--begin::Card-->
      <div
        class="
          card
          mx-2
          d-flex
          align-items-center
          justify-content-center
          shadow-sm
          hover-effect
        "
        style="width: 400px; min-height: 300px; height: 40vh"
      >
        <router-link :to="'/interviewer/invite-candidate/' + jobPostingId">
          <div
            class="
              card-body
              d-flex
              justify-content-center
              align-items-center
              text-center
              flex-column
              p-8
            "
          >
            <a
              class="
                text-gray-800 text-hover-primary
                d-flex
                flex-column
                align-items-center
              "
            >
              <div class="mb-5">
                <img
                  alt="Logo"
                  class="h-70px w-70px"
                  src="/media/avatars/userAccount.png"
                  style="width: 40px"
                />
              </div>
              <div class="fs-2 fw-bolder mb-2">
                {{ $t("modals.interview.candidate.addSingleCandidate") }}
              </div>
            </a>
            <div class="fs-7 fw-bold text-gray-400 text-center">
              {{ $t("modals.interview.candidate.addSingleCandidateDesc") }}
            </div>
          </div>
        </router-link>
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);

    const jobPostingId = ref<any>(Number(route.params["id"]));


    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      isLoading,
      jobPostingId,
    };
  },
});
</script>

<style>
.hover-effect {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
  background-color: #f8f9fa; /* Hafif gri renk */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}
</style>
